import { atom } from 'jotai';
import { Notification } from '../interfaces';
import { DEFAUL_MAIN_PAGE_WRAPPER_CLASS } from '@/utils/constants';

export const singlePhotoModalAtom = atom(null);
export const showSearchAtom = atom(false);
export const colorManagerAtom = atom<{
  background: string;
  text: string;
  navActive: boolean;
  name: string;
}>({
  background: 'bg-black',
  text: 'text-white',
  navActive: false,
  name: '#000000',
});
export const notificationAtom = atom<Notification>({ message: '', link: '' });
export const showStickyNavAtom = atom(true);
export const minHideHeightAtom = atom(60);
export const dontChangeStickyNavAtom = atom(false);
export const slimNavAtom = atom(false);
export const searchTermAtom = atom<string | null>(null);
export const upgradeModalAtom = atom(false);
export const showOwnershipTipModalAtom = atom(false);
export const disableNavBarAtom = atom<boolean>(false);
export const mainPageWrapperClassAtom = atom<string>(DEFAUL_MAIN_PAGE_WRAPPER_CLASS);

export const pageViewsAtom = atom({});

export const uiColors = [
  {
    name: '#000000',
    background: 'bg-black',
    text: 'text-white',
  },
  {
    name: '#667CCB',
    background: 'bg-[#667CCB]',
    text: 'text-white',
  },
  {
    name: '#404C25',
    background: 'bg-[#404C25]',
    text: 'text-white',
  },
  {
    name: '#9D70C0',
    background: 'bg-[#9D70C0]',
    text: 'text-white',
  },
  {
    name: '#FC9CEA',
    background: 'bg-[#FC9CEA]',
    text: 'text-white',
  },
  {
    name: '#5A3000',
    background: 'bg-[#5A3000]',
    text: 'text-white',
  },
  {
    name: '#F4C803',
    background: 'bg-[#F4C803]',
    text: 'text-black',
  },
  {
    name: '#FFFFFF',
    background: 'bg-[#FFFFFF]',
    text: 'text-black',
  },
];
