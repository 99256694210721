import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

export default function ModalContainer({
  children,
  open = true,
  interactionOutside = true,
  onClose,
  className,
  portalBackground = 'bg-black',
  background = 'bg-black',
  border = 'border border-white',
  text = 'text-white',
}: {
  children: React.ReactNode;
  open?: boolean;
  interactionOutside?: boolean;
  className?: string;
  portalBackground?: string;
  background?: string;
  border?: string;
  text?: string;
  onClose?: () => void;
}) {
  return (
    <Dialog.Root
      open={open}
      modal={!interactionOutside}
      onOpenChange={(state) => !state && onClose && onClose()}
    >
      <AnimatePresence mode="wait">
        <Dialog.Portal forceMount>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={clsx(['fixed inset-0 bg-opacity-[0.85] z-40', portalBackground])}
          />
          <Dialog.Content forceMount onOpenAutoFocus={(ev) => ev.preventDefault()}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={clsx([
                'fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-hidden',
                'outline-none z-40 max-w-[2400px]',
                background,
                border,
                text,
                className,
              ])}
            >
              {children}
            </motion.div>
          </Dialog.Content>
        </Dialog.Portal>
      </AnimatePresence>
    </Dialog.Root>
  );
}
