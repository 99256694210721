import { ECHO_TRIGGER_STRING } from '@/utils/constants';
import { trpc } from '@/utils/trpc';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ModalContainer from './ModalContainer';
import Button from './Button';
import { captureException } from '@sentry/nextjs';

export default function EchosTrigger() {
  const { data: user, refetch } = trpc.users.getAuthenticatedUser.useQuery(undefined, {
    staleTime: Infinity,
  });
  const acceptEchoTermsMutation = trpc.echos.acceptEchoTerm.useMutation();
  const router = useRouter();
  const [shouldShowTermsModal, setShouldShowTermsModal] = useState<boolean>(false);
  const [inputSequence, setInputSequence] = useState<string>('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      setInputSequence((prev) => {
        const updatedSequence = prev + key;

        // Start the timer when the first 'e' is typed
        if (updatedSequence === ECHO_TRIGGER_STRING.charAt(0)) {
          startTimer();
        }

        // Check if the current sequence matches 'echo66' up to the length of the current input
        const expectedSequence = ECHO_TRIGGER_STRING.slice(0, updatedSequence.length);
        if (updatedSequence !== expectedSequence) {
          return ''; // Reset the sequence if it doesn't match
        }

        return updatedSequence;
      });
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setInputSequence(''); // Reset the sequence after 10 seconds
    }, 10000);

    setTimer(newTimer);
  };

  useEffect(() => {
    if (inputSequence === ECHO_TRIGGER_STRING) {
      if (user && !user.accepted_echo_terms_at) {
        //fire modal
        console.log('open modal');
        setShouldShowTermsModal(true);
      }
      if (user && !!user.accepted_echo_terms_at) {
        console.log('redirect to next page');
        router.push('/echos');
      }

      setInputSequence(''); // Reset the sequence after detection
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [inputSequence, timer]);

  const handleAcceptTerms = async () => {
    try {
      await acceptEchoTermsMutation.mutateAsync();
      await refetch();
      setShouldShowTermsModal(false);
      router.push('/echos');
    } catch (err) {
      captureException('failed to accept echo terms', err);
    }
  };

  useEffect(() => {
    if (user?.accepted_echo_terms_at && shouldShowTermsModal) {
      router.push('/echos');
    }
  }, [user, shouldShowTermsModal]);

  if (shouldShowTermsModal) {
    return (
      <ModalContainer>
        <div className="p-[30px] flex flex-col gap-[15px] bg-[#0000FF] items-center w-[400px]">
          <p className="font-adieu font-[300] text-[16px] leading-[16px] tracking-[0.1em] text-center uppercase">
            accept NEW terms and conditions
          </p>
          <p className="text-center font-nhg text-[16px] leading-[20.8px] tracking-[0.03em]">
            This is an experimental section of the platform, so we need you to agree to some basic
            terms around AI-generated work. You can review them
            <a
              href="https://stump-snowboard-29e.notion.site/ECHOES-SERVICE-143d35778e9e80ebad86ea300074fb4e?pvs=4"
              target="_blank"
            >
              <span className="ml-1 underline decoration-1 underline-offset-2">here.</span>
            </a>
          </p>
          <p className="font-nhg text-[16px] leading-[20.8px] tracking-[0.03em] weight-[450]">
            Thanks for being part of the experiment.{' '}
          </p>
          <div className="flex justify-center items-center gap-[15px]">
            <Button
              type="quaternary"
              onClick={() => setShouldShowTermsModal(false)}
              disabled={acceptEchoTermsMutation.isLoading}
            >
              GO BACK
            </Button>
            <Button
              type="quaternary"
              onClick={handleAcceptTerms}
              disabled={acceptEchoTermsMutation.isLoading}
              loading={acceptEchoTermsMutation.isLoading}
            >
              ACCEPT
            </Button>
          </div>
        </div>
      </ModalContainer>
    );
  }
  return null;
}
